module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RDC23","short_name":"RDC23","description":"The Roblox Developers Conference (RDC) is an invite-only hybrid (in-person and online) event that brings together the most innovative creators from our global community so they can build connections, learn, and share ideas.\nFeaturing groundbreaking keynote presentations, in-depth breakout sessions, and networking opportunities, RDC is designed to empower you with the knowledge you need to reach your full creative potential on Roblox.","start_url":"/","display":"minimal-ui","icon":"src/content/site-settings/rdc23_fnv_favicon_180x180.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"ee4d79ba6164b70d146ecf22b656c306"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/buildhome/repo/node_modules/@openeventkit/event-site/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/@openeventkit/event-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
